import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import DataTable from "../common/table/DataTable";
import { StatusInActiveIcon, StatusActiveIcon } from "../gogo-icons";
import {
  FiledTypes,
  languages,
} from "../../utilities/constants/global.constants";
import Select from "react-select";

const VehicleSpecificationList = ({
  specifications,
  saveData,
  showOnlySaveButton,
  readOnly,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [specList, setSpecList] = useState([]);
  const [showError, setShowError] = useState(false);
  const [isFormChange, setIsFormChange] = useState(false);
  const [specCount, setSpecCount] = useState(0);

  useEffect(() => {
    if (specifications?.length > 0) {
      const grouped = specifications.reduce((acc, obj) => {
        const key = obj.SpecificationCategoryKey;
        const engObj =
          obj.Values?.length > 0 &&
          obj.Values.filter((obj) => obj.LanguageId === languages.eng);
        const arObj =
          obj.Values?.length > 0 &&
          obj.Values.filter((obj) => obj.LanguageId === languages.ar);
        obj.isSelected = obj.IsActive || false;
        obj.arabicText = arObj?.length > 0 ? arObj[0].SpecificationValue : "";
        obj.IsActive = obj.IsActive || false;
        obj.englishText =
          engObj?.length > 0 ? engObj[0].SpecificationValue : "";
        if (!acc[key]) {
          acc[key] = { title: obj.SpecificationCategory, list: [], key };
        }
        acc[key].list.push(obj);
        if (obj.IsActive) {
          setIsFormChange(true);
          if (!obj.englishText || !obj.arabicText) {
            setShowError(true);
          }
        }
        return acc;
      }, {});
      const groupedFeatures = Object.values(grouped);
      setSpecList(groupedFeatures);
      setSpecCount(specifications?.length);
    }
  }, [specifications]);

  const handleCheckBoxChange = async (row) => {
    const specId = row.SpecificationId;
    const specKey = row.SpecificationCategoryKey;
    const needToUpdateSpec = specList.filter((itm) => itm.key === specKey);
    const objIndex = await needToUpdateSpec[0].list.findIndex(
      (obj) => parseInt(obj.SpecificationId) === parseInt(specId)
    );
    needToUpdateSpec[0].list[objIndex].isSelected = await !needToUpdateSpec[0]
      .list[objIndex].isSelected;
    needToUpdateSpec[0].list[objIndex].englishText = "";
    needToUpdateSpec[0].list[objIndex].arabicText = "";
    setIsFormChange(true);
    await setSpecList([...specList]);
  };

  const handleTextChange = async (e, row, lan) => {
    let inputText;
    if (row?.FieldType === FiledTypes?.DropDown) {
      inputText = e.value;
    } else {
      inputText = e.target.value;
    }
    const specId = row.SpecificationId;
    const specKey = row.SpecificationCategoryKey;
    const needToUpdateSpec = specList.filter((itm) => itm.key === specKey);
    const objIndex = needToUpdateSpec[0].list.findIndex(
      (obj) => parseInt(obj.SpecificationId) === parseInt(specId)
    );
    if (lan === languages.eng) {
      needToUpdateSpec[0].list[objIndex].englishText = inputText;
    } else {
      needToUpdateSpec[0].list[objIndex].arabicText = inputText;
    }
    await setSpecList([...specList]);
    setIsFormChange(true);
  };

  const constructSaveData = async () => {
    let selectedSpec = [];
    let hasError = false;
    for (let i = 0; i < specList.length; i++) {
      for (let j = 0; j < specList[i].list.length; j++) {
        let spec = specList[i].list[j];
        if (spec && spec.isSelected) {
          if (!spec.englishText || !spec.arabicText) {
            hasError = true;
            toast.warning(t("ADD_MISSING_FIELDS"));
            return setShowError(hasError);
          } else {
            hasError = false;
            setShowError(hasError);
            selectedSpec.push({
              SpecificationId: spec.SpecificationId,
              values: [
                {
                  LanguageId: languages.eng,
                  SpecificationValue: spec.englishText,
                },
                {
                  LanguageId: languages.ar,
                  SpecificationValue: spec.arabicText,
                },
              ],
            });
          }
        }
      }
    }
    if (!hasError) {
      saveData(selectedSpec);
    }
  };

  const tableOptions = {
    columns: [
      {
        header: t("SELECT"),
        cellRender: (row) => {
          return (
            <div className="flex justify-center items-center">
              {row.isSelected ? (
                <span
                  onClick={readOnly ? null : () => handleCheckBoxChange(row)}
                >
                  <StatusActiveIcon
                    className={`w-6 h-6 ${
                      readOnly ? "cursor-not-allowed" : "cursor-pointer"
                    }`}
                  />
                </span>
              ) : (
                <span
                  onClick={readOnly ? null : () => handleCheckBoxChange(row)}
                  tabIndex={row.SpecificationId}
                  role="button"
                >
                  <StatusInActiveIcon
                    className={`w-6 h-6 ${
                      readOnly ? "cursor-not-allowed" : "cursor-pointer"
                    }`}
                  />
                </span>
              )}
            </div>
          );
        },
      },
      {
        header: t("NAME"),
        accessor: "Specification",
        cellRender: (row) => {
          return <div className="flex justify-start">{row.Specification}</div>;
        },
      },
      {
        header: t("ENGLISH"),
        cellRender: (row) => {
          return (
            <div className="flex w-full justify-start gap-2 items-center">
              {row?.FieldType === FiledTypes?.AlphaNumeric ? (
                <input
                  className={
                    row.isSelected && showError && !row.englishText?.trim()
                      ? "p-2 border border-red-600 rounded w-3/5 cursor-text"
                      : "p-2 border rounded w-3/5 cursor-text"
                  }
                  type="text"
                  value={row.englishText}
                  onChange={(e) => handleTextChange(e, row, languages.eng)}
                  disabled={!row.isSelected}
                  tabIndex={row.SpecificationId}
                  role="button"
                />
              ) : row?.FieldType === FiledTypes?.DropDown ? (
                <div className="frm-dropdown w-3/5">
                  <Select
                    name="stateId"
                    placeholder={t("SELECT")}
                    height={36}
                    borderRadius={"none"}
                    options={row?.DropdownOptions?.map((x) => {
                      return {
                        label: x.Values.find(
                          (y) => y.LanguageId == languages.eng
                        ).Value,
                        value: x.Values.find(
                          (y) => y.LanguageId == languages.eng
                        ).Value,
                      };
                    })}
                    onChange={(e) => handleTextChange(e, row, languages.eng)}
                    value={row?.DropdownOptions?.map((x) =>
                      x.Values.find((y) => y.LanguageId == languages.eng)
                    )
                      .filter((z) => z.Value === row.englishText)
                      .map((x) => {
                        return { label: x.Value, value: x.Value };
                      })}
                    defaultValue={row?.DropdownOptions?.map((x) =>
                      x.Values.find((y) => y.LanguageId == languages.eng)
                    )
                      .filter((z) => z.Value === row.englishText)
                      .map((x) => {
                        return { label: x.Value, value: x.Value };
                      })}
                    className={`${
                      !row.isSelected ? "bg-gray-100" : "bg-white"
                    }`}
                    showError={true}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        boxShadow: "none",
                        borderColor: "#e5e7eb",
                        height: 38.25,
                        borderRadius: "0px",
                      }),
                    }}
                    isDisabled={!row.isSelected}
                    menuPortalTarget={document.body}
                  />
                </div>
              ) : row?.FieldType === FiledTypes?.Numeric ? (
                <input
                  className={
                    row.isSelected && showError && !row.englishText?.trim()
                      ? "p-2 border border-red-600 rounded w-3/5 cursor-text"
                      : "p-2 border rounded w-3/5 cursor-text"
                  }
                  type="number"
                  value={row.englishText}
                  onChange={(e) => handleTextChange(e, row, languages.eng)}
                  disabled={!row.isSelected}
                  tabIndex={row.SpecificationId}
                  role="button"
                />
              ) : (
                <input
                  className={
                    row.isSelected && showError && !row.englishText?.trim()
                      ? "p-2 border border-red-600 rounded w-3/5 cursor-text"
                      : "p-2 border rounded w-3/5 cursor-text"
                  }
                  type="text"
                  value={row.englishText}
                  onChange={(e) => handleTextChange(e, row, languages.eng)}
                  disabled={!row.isSelected || readOnly}
                  tabIndex={row.SpecificationId}
                  role="button"
                />
              )}
              <span className="w-1/5">
                {
                  row?.UnitOfMeasure?.find(
                    (x) => x.LanguageId === languages.eng
                  ).Value
                }
              </span>
            </div>
          );
        },
      },
      {
        header: t("ARABIC"),
        cellRender: (row) => {
          return (
            <div className="flex justify-end w-full gap-2 items-center">
              <div className="w-1/5 flex justify-end">
                {
                  row?.UnitOfMeasure?.find((x) => x.LanguageId === languages.ar)
                    .Value
                }
              </div>
              {row?.FieldType === FiledTypes?.AlphaNumeric ? (
                <input
                  className={
                    row.isSelected && showError && !row.englishText?.trim()
                      ? "p-2 border border-red-600 rounded w-3/5 cursor-text"
                      : "p-2 border rounded w-3/5 cursor-text"
                  }
                  type="text"
                  value={row.arabicText}
                  onChange={(e) => handleTextChange(e, row, languages.ar)}
                  disabled={!row.isSelected}
                  tabIndex={row.SpecificationId}
                  role="button"
                  dir="rtl"
                />
              ) : row?.FieldType === FiledTypes?.DropDown ? (
                <div className="frm-dropdown w-3/5" dir={"rtl"}>
                  <Select
                    name="stateId"
                    placeholder={t("SELECT")}
                    height={36}
                    borderRadius={"none"}
                    options={row?.DropdownOptions?.map((x) => {
                      return {
                        label: x.Values.find(
                          (y) => y.LanguageId == languages.ar
                        ).Value,
                        value: x.Values.find(
                          (y) => y.LanguageId == languages.ar
                        ).Value,
                      };
                    })}
                    onChange={(e) => handleTextChange(e, row, languages.ar)}
                    value={row?.DropdownOptions?.map((x) =>
                      x.Values.find((y) => y.LanguageId == languages.ar)
                    )
                      .filter((z) => z.Value === row.arabicText)
                      .map((x) => {
                        return { label: x.Value, value: x.Value };
                      })}
                    defaultValue={row?.DropdownOptions?.map((x) =>
                      x.Values.find((y) => y.LanguageId == languages.ar)
                    )
                      .filter((z) => z.Value === row.arabicText)
                      .map((x) => {
                        return { label: x.Value, value: x.Value };
                      })}
                    className={`${
                      !row.isSelected ? "bg-gray-100" : "bg-white"
                    }`}
                    showError={true}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        boxShadow: "none",
                        borderColor: "#e5e7eb",
                        height: 38.25,
                        borderRadius: "0px",
                      }),
                    }}
                    isDisabled={!row.isSelected}
                    menuPortalTarget={document.body}
                  />
                </div>
              ) : row?.FieldType === FiledTypes?.Numeric ? (
                <input
                  className={
                    row.isSelected && showError && !row.englishText?.trim()
                      ? "p-2 border border-red-600 rounded w-3/5 cursor-text"
                      : "p-2 border rounded w-3/5 cursor-text"
                  }
                  type="number"
                  value={row.arabicText}
                  onChange={(e) => handleTextChange(e, row, languages.ar)}
                  disabled={!row.isSelected}
                  tabIndex={row.SpecificationId}
                  role="button"
                  dir="rtl"
                />
              ) : (
                <input
                  className={
                    row.isSelected && showError && !row.englishText?.trim()
                      ? "p-2 border border-red-600 rounded w-3/5 cursor-text"
                      : "p-2 border rounded w-3/5 cursor-text"
                  }
                  type="text"
                  value={row.arabicText}
                  onChange={(e) => handleTextChange(e, row, languages.ar)}
                  disabled={!row.isSelected}
                  tabIndex={row.SpecificationId}
                  role="button"
                  dir="rtl"
                />
              )}
            </div>
          );
        },
      },
    ],
    allowSorting: false,
  };

  return (
    <>
      <div className="pb-8">
        {specList?.map((spc, index) => (
          <div key={index}>
            <p className="spec-title mt-4">{spc.title}</p>
            <div className="mt-4 p-4 border rounded">
              <DataTable
                tableOptions={tableOptions}
                data={spc.list}
                showPagination={false}
                customPageSize={spc.list && spc.list.length}
              />
            </div>
          </div>
        ))}
      </div>
      {!readOnly && specList?.length > 0 ? (
        showOnlySaveButton ? (
          <div className="flex justify-end">
            <button
              className="btn btn-primary p-2 sm:w-36 w-32 uppercase"
              type="submit"
              onClick={() => constructSaveData()}
              disabled={!isFormChange}
              tabIndex={specCount + 1}
              role="button"
            >
              {t("SAVE")}
            </button>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 my-6">
            <div className="flex flex-row gap-4 sm:justify-start justify-center">
              <button
                type="button"
                className="btn btn-cancel p-2 sm:w-36 w-32 uppercase"
                onClick={() => history.goBack()}
                tabIndex={specCount + 2}
                role="button"
              >
                {t("LBL_CANCEL")}
              </button>
              <button
                className="btn btn-primary p-2 sm:w-36 w-32 uppercase"
                type="submit"
                onClick={() => constructSaveData()}
                disabled={!isFormChange}
                tabIndex={specCount + 3}
                role="button"
              >
                {t("SAVE")}
              </button>
            </div>
          </div>
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default VehicleSpecificationList;
